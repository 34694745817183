<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Sequence Mision" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Level Mission</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <quest-sequence-create-form :display_order="total_items" @create="create" />
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <quest-sequence-table @getList="getList" :sequences="sequences" />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import QuestSequenceCreateForm from "./_components/QuestSequenceCreateForm.vue";
import QuestSequenceTable from "./_components/QuestSequenceTable.vue";
export default {
  components: {
    QuestSequenceCreateForm,
    QuestSequenceTable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      sequences: [],
      editLanguage: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        this.sequences = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    create(){
      this.getList();
    }
  },
};
</script>
