<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new quest"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <b-form-group
        label="Mission Name"
        label-for="h-mission-name"
      >
        <ui-component :component-data="name" class="border" />
      </b-form-group>
      <b-form-group
        label="Mission Description"
        label-for="h-mission-description"
      >
        <ui-component :component-data="description" class="border" />
      </b-form-group>
      <b-form-group
        label="NUMBER OF COMPLETED LESSONS"
        label-for="required_progress_mark"
      >
        <b-form-input
          v-model="required_progress_mark"
          id="required_progress_mark"
          name="required_progress_mark"
          type="number"
        />
      </b-form-group>

      <b-form-group
        label="LEVEL"
        label-for="sequence_mark"
      >
        <b-form-input
          v-model="sequence_mark"
          id="sequence_mark"
          name="sequence_mark"
          type="number"
        />
      </b-form-group>

      <hr>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="create">Create</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";

export default {
  props: {
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      isModalShow: false,
      isLoading: false,
      required_progress_mark: null,
      sequence_mark: null,
      name: {
        meta: {
          default_lang: "en"
        },
        type: "translatable-text",
        value: null,
        translations:[
        ]
      },
      description: {
        meta: {
          default_lang: "en"
        },
        type: "translatable-text",
        value: null,
        translations:[{
          language: this.$store.getters["auth/language"],
          value: null
        }]
      },
    };
  },
  methods: {
    async create() {
      let isNameFilled = this.name.translations.find((x)=>{
        return x.value.length > 0
      })
      if (!this.required_progress_mark || !this.sequence_mark || !isNameFilled){
        this.$store.dispatch("pushErrorNotify", {
          text: "All fields are required",
        });
        return
      }
      this.isLoading = true;
      await service.create({
        display_order: this.display_order,
        required_progress_mark: this.required_progress_mark,
        sequence_mark: this.sequence_mark,
        name: this.name,
        description: this.description
      });
      this.isLoading = false;
      this.isModalShow = false;
      this.required_progress_mark = null;
      this.sequence_mark = null;
      this.name = {
        meta: {
          default_lang: "en"
        },
        type: "translatable-text",
        value: null,
        translations:[
        ]
      },
      this.description = {
        meta: {
          default_lang: "en"
        },
        type: "translatable-text",
        value: null,
        translations:[{
          language: this.$store.getters["auth/language"],
          value: null
        }]
      },
      this.$emit("create");
      this.$store.dispatch("pushSuccessNotify", {
        text: "Mission created",
      });
    },
    cancel(){
      this.isModalShow = false;
    },
  },
};
</script>
